.industries-overview {
  width: 95%;
  margin: auto;
  header {
    @include flexContainer(column, center, center);
    //   background: grey;
    padding-top: 8vh;
  }

  h1 {
    margin-top: 10vh;
    margin-right: auto;
    margin-left: 6rem;
    margin-bottom: 10vh;
  }
  > ul {
    @include flexContainer(column, center, center);
    padding: 0;
    min-height: 58vh;

    > * {
      margin-bottom: 4rem;

      img {
        width: 100%;
      }
    }
  }
}

.industry-page {
  @include flexContainer(column, center, center);
  margin-top: 16vh;
  margin-bottom: 10vh;

  h3 {
    font-size: $m-size;
    margin: 0;
    margin-bottom: 2rem;
    // margin-right: auto;
  }

  h4 {
    font-size: $l-size;
    width: 100%;
    margin-bottom: 0.4rem;
  }

  > div {
    @include flexContainer(column, center, center);
    margin-top: 6vh;

    > div {
      @include flexContainer(row, center, center);
      position: relative;
      width: 60vw;
      margin: 0 3rem;
      flex-wrap: wrap;

      > div {
        margin-right: auto;
      }

      ul {
        margin-right: auto;

        .sub{
          font-size: 80%;
          list-style: none;
          margin-left: 2.5rem;
        }
      }
    }
    img {
      max-width: 200px;
      width: 150px;
      margin: 2rem 5rem;
    }
  }
}

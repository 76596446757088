.audit4 {
  position: relative;
  overflow-x: hidden;
  header {
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100vw;
    min-height: 90vh;
    margin-top: 10vh;
    @include flexContainer(column, center, center);
    padding: 0 4rem;
    text-align: center;
    position: relative;
  }

  img {
    // background-image: url("../../../public/images/clientLogos/desjardins.png");
    width: 200px;
    height: auto;
  }

  .leadership-card {
    @include flexContainer(row, center, center);
    border-radius: 4px;
    padding: 1rem 2rem;
    margin-left: 2rem;
    -webkit-box-shadow: 1px 2px 1px 2px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 1px 2px 1px 2px #ccc; /* Firefox 3.5 - 3.6 */
    // box-shadow: 2px 2px 1px 2px #ccc;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 65%;
    margin: 2rem auto;

    .leadership-logo2 {
      width: 90px;
      margin: 2rem 1rem;
    }
  }

  ul.check-list {
    @include flexContainer(row, center, center);

    background: #fafafa;
    width: 100%;
    height: 30vh;
    justify-content: space-around;
    padding: 0;

    li {
      @include flexContainer(column, center, center);
      width: 25%;
      list-style: none;
      font-size: 2.2rem;
    }
  }

  ul {
    list-style: none;
  }

  i {
    margin-right: 1rem;
  }

  .main-slogan {
    @include flexContainer(row, center, center);
    background: $primary-color;
    height: 35vh;
    font-size: 3.8rem;
    color: $secondary-color;
    margin-bottom: 10vh;

    span {
      width: 70%;
      text-align: center;
    }
  }

  .project-leadership-section {
    @include flexContainer(column, center, center);
    margin: 10vh auto;
  }
}

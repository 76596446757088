.custom-modal{
    @include flexContainer(column, center, center);
    position: absolute;
    width: 100%; height: 100vh;
    background: white;
    background: url('https://cutewallpaper.org/21/white-background-gif/Abstract-Grey-DNA-Molecular-Structure-Animated-Background-.gif') top / cover no-repeat;

    &::before{
        content: '';
        position: absolute;
        width: 100%;height: 100vh;
        background:linear-gradient(rgba(255, 255, 255,0.88), rgba(255, 255, 255,0.97));
        z-index: 0;
    }

    h2{
        position: absolute;
        top: 40%;
        
        // margin-bottom: 5rem;
    }

    .Typewriter{
        font-size:5rem;
        z-index: 2;
    }

    .grid-2{
        position: absolute;
        top: 25%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        grid-template-columns: 1fr 1fr;
        opacity: 0;
        transition: 0.6s ease-in-out all;


        div{
            z-index: 10;
            width:37vw;
            height: 30vh;
            margin:0;
            padding: 2rem;
            box-shadow: 0 1px 1px rgba(153, 153, 153, 0.11), 
              0 2px 2px rgba(153, 153, 153, 0.11), 
              0 4px 4px rgba(153, 153, 153, 0.11), 
              0 6px 8px rgba(153, 153, 153, 0.11),
              0 8px 16px rgba(153, 153, 153, 0.11);
            border-radius: 15px;
            transition: 0.2s ease-in-out;
            background:white;

            &:hover{

                transform: scale(105%);
                transition: 0.15s ease-in-out;

                box-shadow: 0 1px 1px rgba(48, 48, 48, 0.11), 
              0 2px 2px rgba(48, 48, 48, 0.11), 
              0 4px 4px rgba(48, 48, 48, 0.11), 
              0 6px 8px rgba(48, 48, 48, 0.11),
              0 8px 16px rgba(48, 48, 48, 0.11);
            }


            p:nth-of-type(1){

                font-size: 1.3rem;
                font-weight: bold;
                text-transform: uppercase;
                background: $secondary-color;
                padding:0.5rem;
                padding-left: 1rem;
                border-radius: 15px;
                color:white;
            }

            h3{
                
            }
            p:nth-of-type(2){
                font-size: 1.3rem;
            }
        }

        

        >a:nth-of-type(1) >div{
                margin-left: auto;
                margin-right:2rem;
        }
        >a:nth-of-type(2) >div{
            // margin:0;
            margin-left: 2rem;
            margin-right: auto;

        }
    
    }
}
nav {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: end;
  min-height: 10vh;
  background: $primary-color;
  color: white;
  box-shadow: 5px 0px white;
  opacity: 0.9;
  z-index: 3;

  > * {
    // margin: auto 2rem;
  }

  .hamburger {
    width: 100px;
    height: 30px;
    font-size: 3rem;
    color: white;
    background: none;
    border: none;
  }

  .nav-hamburger {
    width: 40px;
    height: 25px;
    position: relative;
    // margin: 0 1rem;
    margin: 8px 36px;
    // margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      // transform: translateX(10px);

      span:nth-child(1) {
        transform: rotate(45deg);
        width: 50%;
        background: darken($secondary-color, 90%);
      }

      span:nth-child(2) {
        transform: rotate(-45deg);
        width: 50%;
        height: 20%;
        background: darken($secondary-color, 20%);
      }

      span:nth-child(3) {
        transform: rotate(45deg);
        width: 50%;
        height: 20%;
        top: 0;
        background: $secondary-color;
      }
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      // background: -webkit-linear-gradient(220deg, $secondary-color, $secondary-color);
      background: white;
      opacity: 0.95;
      -webkit-text-fill-color: transparent;
      border-radius: 9px;

      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
  }

  .nav-hamburger span:nth-child(1) {
    top: 0px;
  }

  .nav-hamburger span:nth-child(2) {
    top: 12px;
  }

  .nav-hamburger span:nth-child(3) {
    top: 24px;
  }

  .nav-hamburger:focus {
    outline: none;
    user-select: none;
  }

  // open
  .nav-hamburger.open {
    // height: 30px;
  }

  .nav-hamburger.open span:nth-child(1) {
    top: 14px;
    width: 0%;
    left: 50%;
  }

  .nav-hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 70%;
    height: 17%;
    left: 0%;
    top: 9px;
  }

  .nav-hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 9px;
    width: 70%;
    height: 17%;
    left: 0%;
  }

  .custom-select {
    // select {
    //   -moz-appearance: none; /* Firefox */
    //   -webkit-appearance: none; /* Safari and Chrome */
    //   appearance: none;

    //   option {
    //     display: none;
    //   }
    // }
  }

  .select-selected {
    position: relative;
  }

  // .select-selected:after {
  //   position: absolute;
  //   content: "";
  //   top: 50%;
  //   right: 0%;
  //   width: 0;
  //   height: 0;
  //   border: 4px solid transparent;
  //   border-color: #fff transparent transparent transparent;
  // }

  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  }

  .select-items div,
  .select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
  }

  .select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .select-hide {
    display: none;
  }

  .select-items div:hover,
  .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  select {
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    background: none;
    color: white;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    &:focus {
      outline: none;
    }
  }

  .company-name {
    letter-spacing: 2px;
  }

  ul {
    position: absolute;
    top: 10vh;
    right: 0%;
    list-style: none;
    min-height: 33vh;
    width: auto;
    padding: 2rem;
    z-index: -1;
    transform: translateX(100%);
    // transform: translateX(200%);
    transition: 0.7s ease-in-out;
    background: $primary-color;
    border-radius: 10px;
    // border-radius: 100px 50px 100px 100px;

    &.show {
      display: block;
      z-index: 4;
      transform: translateX(0%);
    }
    li {
      margin: 2rem 0;
      a {
        color: white;
      }
    }

    .underline {
      border: 2px solid $secondary-color;
      color: $secondary-color;
      padding: 0.1rem 0.4rem;
    }
  }

  a .nav-logo {
    max-width: 120px;
    width: 100%;
  }
}

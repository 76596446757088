@media (max-width: 800px) {
  .custom-modal{

    .grid-2{
      flex-direction: column;
      padding: 2rem;
      transform:translateY(0px)!important;
      top:1%;
      position: relative;

      div{
        font-size: 80%;
        margin:2rem auto !important;
        width: 90%;
        height: fit-content;

      }

      >a:nth-of-type(1) >div{
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }
      
    }

    .Typewriter{
      font-size:2.5rem;
      max-width: 90%;
      width: fit-content;
  }

  }

  .general-description {
    flex-direction: column;
  }

  .solution-overview > div > div {
    margin: auto;
  }

  .solution-overview {
    @include flexContainer(column, center, top);

    > div {
      width: 85%;
      @include flexContainer(column, center, top);
      flex-flow: column;

      > div {
        width: 85%;

        p {
          display: block;
        }
      }
      p {
        display: block;
        position: relative;
        display: flex;
        width: 100%;
        opacity: 1;
        transition: all 0.6s ease-in-out;
        transition-delay: 2s;
        background: $primary-color;
        color: white;
        border-radius: 10px;
        padding: 1.6rem;
      }

      &:hover {
        p {
          position: relative;
          display: flex;
          // width: 100%;
          opacity: 1;
          transition: all 0.6s ease-in-out;
          transition-delay: 2s;
          background: $primary-color;
          border-radius: 10px;
          padding: 1.6rem;
          color: black;
          // color: white;
          // transform: translateY(1%);
        }
      }
    }
  }

  .bridge-banner {
    .successful-projects {
      margin: 4rem auto;
    }
    // div {
    //   width: 100%;
    // }

    .quote-and-person {
      align-items: flex-end;
      margin: 2rem 2rem;
      .quote {
        width: 100%;
      }
      .person {
        width: 0%;
      }
    }
  }

  .solution-timeline {
    height: auto;
    min-height: 70vh;
    h2 {
      font-size: 3.5rem;
      margin: 2rem auto;
    }
    .timeline-diagram {
      width: 85vw;
      font-size: 1.2rem;
    }
  }

  .social-proof {
    width: 95vw;
    text-align: center;
    > div > div {
      width: 75px;
      height: 75px;
    }
  }

  footer {
    .grid-2{
      grid-template-columns: 1fr;
    }

    div:nth-of-type(2){
      ul{
        padding:0;
      }
    }

    > p:first-of-type {
      width: 87%;
      
    }

    p.copyright-text {
      width: 100%;
      text-align: center;
      margin:2rem 0;
    }
  }

  .industries-overview{
    width: 100%;
  
  h1 {
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    text-align: center;
  }


  h3{
    text-align: center;
  }

  .industry-page {
    margin: auto;
    margin-top: 15vh;
    margin-bottom: 10vh;

    h1 {
      text-align: center;
    }

  }
}

  //tech management advisory page

  .tech-management-advisory {
    header {
      padding: 2rem;


      h1{
        font-size: $l-size;
        width: 100%;
      }
      p{
        width: 100%;
        font-size: 1.4rem;
      }
    }

    .tech-mgmt-advisory {
      width: 90%;
      margin: auto;

      span {
        margin-right: auto;
      }

      blockquote{
        margin:0;
      ul {
        padding: 0;

        li{
          width: 100%;
          margin-bottom: 1rem;
          text-align: left;

          i{
            margin-right: 1rem;
          }
        }
      }
    }
      a {
        width: 80%;
        @include mainButton()
        font-size:1.6rem;

      }
    }

    .promo-block-services {
      h2 {
        width: 80%;
        font-size: 3.5rem;
      }

      > div {
        width: 90%;
      }

      .main-promo-text{
        margin: 2rem auto;
      a.btn{
        background: $secondary-color;
        color: $primary-color;
        font-size: 2rem;
        margin-top: 3rem;
      }
      }
    }

    .section.succesful-projects {
      flex-direction: column;
      > div {
        // width: 50%;
      }

      .next-to-person-text {
        img {
          width: 100%;
        }
      }
    }

    .section.bg-dark {
      z-index: 0;
      padding: 3rem 0;

      h2 {
        width: 80%;
        font-size: 3.5rem;
      }
      ul {
        width: 80%;
        padding: 0;

        li {
          margin: 1.5rem auto;
        }

        li i {
          margin-right: 1rem;
        }
      }
    }

    .clients-we-ve-help {
      width: 80%;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    margin-top: 15vh;

    > * {
      width: 90%;
    }
    form {
      margin: 3rem auto;
      width: 90%;
      p {
        width: 100%;
      }
    }
  }

  .about {
    width: 100%;
    padding:3rem;
    box-sizing: border-box;

    

    .container {
      margin: 0;

     
    }

    .team-members {
      display: flex;
      flex-direction: column;
      margin: 0;
      width: 100%;
      flex-wrap: nowrap;

      .member {
        width: 100%;
        padding: 0;
        margin: 3rem 0;
      }

      > div {
        width: 80%;
        margin: 4rem auto;
      }
    }
  }

  .audit4 {
h1{
  font-size: 3rem;
}
    
    > div {
      
      
      ul.check-list {
        flex-direction: column;
        height: fit-content;
        margin: 2rem 0;
        padding-top: 2rem;


        li {
          width: 80%;
          font-size: 1.5rem;
          text-align: center;
        }
      }
    }
    .main-slogan {
      height: fit-content;
      padding: 4rem 0;
      font-size: 2.5rem;
    }

    .leadership-card {
      width: 85%;
      flex-direction: column;
      padding:1rem;
      box-sizing: border-box;

      .leadership-logo2{
        margin: 0rem 0.3rem;
        width: 80px;
      }
    }
  }
}

.solution-overview {
  @include flexContainer(column, center, center);
  min-height: 100vh;
  margin: auto 4rem;
  color: $primary-color;
  //   border: 2px solid orange;

  h2 {
    font-size: $xl-size;
  }

  i {
    color: $secondary-color;
  }

  > div {
    @include flexContainer(row, center, end);
    flex-wrap: wrap;
    // justify-content: baseline;
    // width: 22%;
    // margin: 1rem 5rem;
    // position: relative;
  }

  > div > div {
    justify-content: baseline;
    width: 30%;
    margin: 1rem 10rem;
    position: relative;

    &:before {
      content: "";
      background: $primary-color;
      position: absolute;
      width: 10px;
      height: 10px;
    }
    &:after {
      content: "";
      background: $secondary-color;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 10px;
      left: 10px;
    }

    h4 {
      font-size: $m-size;
      font-weight: bold;
    }

    // p {
    //   display: block;
    //   // opacity: 0;
    //   opacity: 1;
    //   color: white;
    //   transition-delay: 2s;
    //   transition: all 0.6s ease-in-out;
    // }

    // &:hover {
    //   // p {
    //   //   position: absolute;
    //   //   display: flex;
    //   //   width: 100%;
    //   //   opacity: 1;
    //   //   transition: all 0.6s ease-in-out;
    //   //   transition-delay: 2s;
    //   //   background: $primary-color;
    //   //   border-radius: 10px;
    //   //   padding: 1.6rem;
    //   //   color: white;
    //   //   // transform: translateY(1%);
    //   // }

    //   &:nth-of-type(1) p,
    //   &:nth-of-type(2) p,
    //   &:nth-of-type(3) p {
    //     top: -120%;
    //     transform: translateY(-10rem);
    //     transform-origin: bottom;
    //     width: 100%;
    //   }
    // }

    &.active {
      p {
        display: block;
      }
    }
  }
}

.solution-timeline {
  @include flexContainer(column, center, center);
  background: $primary-color;
  width: 100%;
  height: 100vh;

  h2 {
    font-size: $xl-size;
    color: white;
    width: 65%;
  }

  a{
    background: $secondary-color;
    padding: 2rem;
    color: white;
    border: 10px;
    margin: 5rem;
  }


  .timeline-diagram {
    border-left: 3px white solid;
    border-bottom: 3px white solid;
    border-radius: 0px 10px;
    width: 60vw;
    .activity {
      background: orange;
      padding: 0.6rem 1rem;
      margin: 1.7rem;
      border-radius: 20px;
      opacity: 0.8;
      color: white;
      font-weight: bold;
      transition: 0.4s all ease-out;

      &:hover {
        opacity: 0.9;
        transition: 0.4s all ease-out;
      }

      &:nth-of-type(1) {
        background: rgb(255, 69, 96);
      }
      &:nth-of-type(2) {
        background: rgb(119, 93, 208);
      }
      &:nth-of-type(3) {
        width: 60%;
        margin-left: auto;
        background: rgb(0, 143, 251);
      }
      &:nth-of-type(4) {
        width: 45%;
        margin-left: auto;
        background: rgb(0, 227, 150);
      }
      &:nth-of-type(5) {
        width: 60%;
        margin-left: auto;
        background: rgb(254, 176, 25);
      }
    }
  }
}

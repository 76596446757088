@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap");

html {
  font-size: 62.5%;
}

body {
  font-family: "Manrope", sans-serif;
  // font-family: "Noto Sans KR", Arial, sans-serif;
  font-size: $m-size;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.container {
  width: 90%;
  margin: auto;
}

/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h2 {
  letter-spacing: -1px;
}

p {
  font-weight: 300;
}

a {
  text-decoration: none;
  color: $dark-color;
}

.btn{
  display: block;
background: none;
color: $secondary-color;
font-weight: 500;
padding: 0.5rem 1rem;
margin: 1rem auto;
// border: 3px solid $secondary-color;
border-radius: 5px;
width: fit-content;
line-height: 1.5;
font-size: 2.3rem;
cursor: pointer;

&:hover{

}

}


.grid-2{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

span.vertical-bar-style{
  display: inline-block;
  font-size: 5rem;
color: $secondary-color;
height: 10px;
transform:translateY(100%);
}
span.vertical-bar-style2{
  display: inline-block;
  font-size: 3rem;
color: $secondary-color;
height: 10px;
transform:translateY(50%);
}

/*  Mixins  */

@mixin flexContainer($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin mainButton() {
  display: block;
  font-size: $m-size;
  background: $primary-color;
  color: white;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: $primary-color;
    color: $secondary-color;
    text-shadow: $secondary-color 0.5px 0.5px 0px;
    border: $secondary-color 2px solid;
    transition: all 0.25s ease-in-out;
  }
}



//=== Drawing effect

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    border-radius: 5px;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing($secondary-color, $primary-color, 4px, bottom, right);
  
}


// .styled-bars {
//   position: absolute;
//   bottom: -5vh;

//   width: 20%;

//   .styled-bar:nth-child(2) {
//     // left: -10vw;
//     width: 35%;
//     height: 0.75rem;
//     background: $secondary-color;
//     opacity: 0.9;
//     // border-radius: 50% 50% 50% 50%;
//     z-index: 1;
//   }
//   .styled-bar:nth-child(1) {
//     // left: 10vw;
//     width: 35%;
//     height: 0.75rem;
//     background: $primary-color;
//     opacity: 0.9;
//     // border-radius: 50% 50% 50% 50%;
//     margin-left: auto;
//     transform: translateY(-50%);
//     z-index: -10;
//   }
// }

footer {
  @include flexContainer(column, flex-start, flex-start);
  background: $primary-color;
  color: white;
  padding: 4rem;

  h4 {
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 1px;
    
  }

  
  img{
    display: block;
    width: 130px;
    margin-left:auto;
    transform: translateX(20px)
  }

  div:nth-of-type(2){
    margin-left: auto;
    a{
    color:$secondary-color;
      };
    ul{
      list-style: none;
      text-align: right;
      transition: 0.4s ease-in-out;
      a{
      &:hover{
        transform: scale(11 0%);
      }}
    }
    
  }

  > p:first-of-type {
    font-weight: 600;
    width: 40%;
    font-size: 1.75rem;
    text-align: left;
  }

  .icon-bg > a > i {
    color: white;
    margin: 0.5rem 0.8rem;
    font-size: 3rem;
    transition: all 0.5s ease-in-out;
    &:hover {
      background: $primary-color;
      color: $secondary-color;
      text-shadow: $secondary-color 0.5px 0.5px 0px;
      transition: all 0.3s ease-in-out;
    }
  }


  .copyright-text a {
    color: white;
    font-weight: medium;
    letter-spacing: 3px;
    padding-left: 5px;
    margin:0;

    
    &::hover{
    transform: scale(1.3);}
  }
}

.contact {
  @include flexContainer(row, center, center);
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 95%;
  margin: auto;
  min-height: 90vh;
  margin-top: 10vh;

  > * {
    @include flexContainer(column, center, center);
    width: fit-content;
    margin: auto;
    > * {
      margin-right: auto;
      margin: 0 auto;
    }
  }

  form {
    @include flexContainer(column, center, center);

    input {
      border: none;
      background: #fafafa;
      width: 85%;
      height: 2.5rem;
    }

    p {
      width: 50vw;

      > * {
        width: 100%;
      }
    }
    textarea {
      border: none;
      background: #fafafa;
      width: 85%;
    }

    button {
      border: none;
      padding: 1.5rem 2rem;
    }
  }
}

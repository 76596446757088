.bridge-banner {
  .successful-projects {
    width: 80%;
    background: $secondary-color;
    padding: 4rem;
    color: white;
    margin: 4rem 0;
    // color: $primary-color;

    .big-number {
      font-size: 10rem;
      font-weight: bold;
    }

    p:nth-of-type(1) {
      font-weight: bold;
      font-size: 3rem;
    }
    p:nth-of-type(2) {
      font-weight: normal;
      font-size: 80%;
      text-transform: uppercase;
    }
  }

  .quote-and-person {
    @include flexContainer(row, center, center);
    margin: auto 4rem;
    .quote {
      width: 50%;
    }

    .person {
      display: block;
      position: relative;

      width: 50%;
      background: url(../../media/img/murielPhoto.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: -1;

      &::before {
        content: "";
        display: block;
        padding-top: 85%;
      }
    }

    a {
      @include mainButton;
    }
  }
}

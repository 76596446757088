.tech-management-advisory {
  // @include flexContainer(row, center, center);
  // min-height: 80vh;
  // background: #000;

  

  // margin: 0 5rem;

  .styled-bars {
    margin-bottom: 10rem;
    .styled-bar {
    }
  }

  header {
    // background-image: url("https://images.unsplash.com/photo-1580565431493-896dc95e5a08?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80");
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100vw;
    min-height: 90vh;
    margin-top: 10vh;
    @include flexContainer(column, center, center);
    padding: 0 4rem;
    text-align: center;
    position: relative;

    background: url('https://cutewallpaper.org/21/white-background-gif/Abstract-Grey-DNA-Molecular-Structure-Animated-Background-.gif') top / cover no-repeat;

  &::before{
      content: '';
      position: absolute;
      width: 100%;height: 100vh;
      background:white;
      opacity: 0.9;
      z-index: 0;}

    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: 15vw;
    //   height: 100vh;
    //   left: -20vw;
    //   background: $secondary-color;
    //   opacity: 0.4;
    //   z-index: 1;
    //   transform: skew(-35deg);
    // }
    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 20vw;
    //   height: 100vh;
    //   left: -18vw;
    //   background: $secondary-color;
    //   opacity: 0.15;
    //   z-index: 1;
    //   transform: skew(-35deg);
    // }

    > * {
      width: 70%;
      z-index: 2;
    }
  }

  .tech-mgmt-advisory {
    @include flexContainer(column, center, center);
    // background: $secondary-color;
    min-height: 60vh;
  }

  .section {
    // margin: 5rem auto;
    @include flexContainer(column, center, center);

    &.succesful-projects {
      @include flexContainer(row, center, center);
      width: 90vw;
      margin: auto;

      > div {
        @include flexContainer(column, center, center);
      }

      .next-to-person-text {
        margin-top: 5rem;
        h3 {
          font-size: $m-size;
          margin: 0;
        }
        span {
          font-size: $s-size;
          margin-bottom: 1.5rem;
        }
      }
    }

    &.bg-dark {
      position: relative;
      // background: $primary-color;
      background-image: url("https://images.unsplash.com/photo-1518173184999-0381b5eb56d7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80");
      margin: 0;
      color: white;
      z-index: -10;
      min-height: 60vh;

      &::after {
        content: "";
        position: absolute;
        background: $primary-color;
        opacity: 0.9;
        width: 100%;
        height: 100%;
        z-index: -10;
      }
    }
  }

  ul {
    li {
      @include flexContainer(row, left, center);
      list-style: none;

      i {
        font-size: 1.2rem;
      }
    }
  }
  // a {
  //   @include mainButton;
  //   width: 40vw;
  //   // margin: auto;
  // }

  .promo-block-services {
    @include flexContainer(row, center, center);
    background: $primary-color;
    flex-wrap: wrap;
    color: white;
    margin: 5rem auto;
    padding: 6rem 0;

    h2 {
      font-size: $xl-size;
      display: block;
      width: auto;
      margin: auto;

      // margin: 0 5rem;
    }
    > div {
      width: 40vw;
      padding: 0 4rem;

      h4 {
        font-size: 2.5rem;
        color: $secondary-color;
      }
      span {
        font-size: 1.5rem;
      }
      p {
        font-size: $m-size;
      }
    }
    .main-promo-text {
      color: $secondary-color;
      font-size: $xl-size;
    }
  }
  .clients-we-ve-help {
    // width: 50%;
    margin: 10rem auto;
    h3 {
      font-weight: 500;
      color: $primary-color;
      font-size: $l-size;
    }
    p {
      font-size: 1.6rem;
      color: $primary-color;
    }
    a {
      margin: 4rem auto;
      
      // background: $primary-color;
    }
  }
}

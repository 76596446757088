.about {
  min-height: 90vh;
  margin-top: 20vh;
  @include flexContainer(column, center, center);

  > div {
    // @include flexContainer(row, center, center);
  }

  .team-members {
    @include flexContainer(row, center, center);
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 7rem auto;

    .member {
      width: 35vw;
      margin: 0 4rem;
      // margin-top: 4rem;

      .profile-picture {
        @include flexContainer(column, center, center);

        font-size: 2.4rem;
      }

      span {
        @include flexContainer(column, center, center);
        font-size: 2.3rem;
        text-align: center;
        margin-top: 1rem;
      }

      h5 {
        text-align: center;
      }

      img {
        width: 200px;
      }
    }
  }
}

.social-proof {
  @include flexContainer(row, center, center);
  flex-wrap: wrap;
  margin: auto;
  width: 90vw;
  padding: 7rem 0;

  h2 {
    width: 100%;
    font-size: $xl-size;
  }

  div {
    @include flexContainer(row, center, center);
    flex-wrap: wrap;
    margin: auto;
    width: 90vw;

    > div {
      @include flexContainer(row, center, center);
      width: 130px;
      height: 130px;
      margin: 2rem;
    }

    > img {
      width: 100%;
      height: auto;
    }
  }

  a {
    @include mainButton;
    display: block;
    width: 50vw;
    margin: 5rem auto;
  }
}

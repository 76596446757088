// Colors

$primary-color: rgb(14, 30, 73);
$secondary-color: #ffa105;
// $tertiary-color: #96f1a9;
$dark-color: #292929;
$light-grey: #fafafa;

// Spacing
$s-size: 1.2rem;
$m-size: 1.9rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$xxl-size: 8rem;
$desktop-breakpoint: 45rem;

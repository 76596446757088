.hero {
  @include flexContainer(column, center, center);
  min-height: 100vh;
  // margin: auto 4rem;
  padding: 0 4rem;
  background-image: url("https://images.unsplash.com/photo-1480944657103-7fed22359e1d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=889&q=80");
  background-size: cover;
  color: white;
  overflow: hidden;
  position: relative;
  z-index: -1;

  button {
    z-index: 5;
  }

  h1,
  p {
    z-index: 2;
    opacity: 0.84;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    background: $primary-color;
    opacity: 0.86;
    z-index: 0;
  }
}

.hero-mask {
  position: absolute;
  left: -20%;
  width: 10vw;
  height: 100vh;
  background: $secondary-color;
  opacity: 0.3;
  z-index: 1;
  transform: skew(-35deg);
  transition: 0.5s ease-in-out;

  &:hover {
    opacity: 1;
    transition: 0.4s ease-in-out;
  }

  &.active {
    left: -10%;
    opacity: 0.8;
  }

  &::before {
    content: "";
    position: absolute;
    width: 15vw;
    height: 100vh;
    background: $secondary-color;
    opacity: 0.3;
    z-index: 1;
    // transform: skew(-45deg);
  }
  &::after {
    content: "";
    position: absolute;
    width: 20vw;
    height: 100vh;
    background: $secondary-color;
    opacity: 0.15;
    z-index: 1;
    // transform: skew(-35deg);
  }
}
.hero-mask2 {
  position: absolute;
  bottom: 0%;
  right: -10%;
  width: 10vw;
  height: 100vh;
  background: $secondary-color;
  opacity: 0.3;
  z-index: 1;
  transform: skew(-35deg);
  transition: 0.5s ease-in-out;

  &:hover {
    opacity: 1;
    transition: 0.4s ease-in-out;
  }

  &.active {
    right: 0%;
    opacity: 0.7;
  }

  &::before {
    content: "";
    position: absolute;
    width: 15vw;
    height: 100vh;
    background: $secondary-color;
    opacity: 0.3;
    z-index: 1;
    // transform: skew(-45deg);
  }
  &::after {
    content: "";
    position: absolute;
    width: 20vw;
    height: 100vh;
    background: $secondary-color;
    opacity: 0.15;
    z-index: 1;
    // transform: skew(-35deg);
  }
}
